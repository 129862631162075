import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";


const Portal = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/centralbanknews`}/>
    <Route path={`${match.url}/newsinformation/centralbanknews`} component={asyncComponent(() => import('./newsinformation/centralbanknews'))}/>
    <Route path={`${match.url}/newsinformation/nerkharz`} component={asyncComponent(() => import('./newsinformation/nerkharz'))}/>
    <Route path={`${match.url}/newsinformation/tablo`} component={asyncComponent(() => import('./newsinformation/tablo'))}/>
    <Route path={`${match.url}/newsinformation/bongah`} component={asyncComponent(() => import('./newsinformation/bongah'))}/>
    <Route path={`${match.url}/newsinformation/FormsInstructions`} component={asyncComponent(() => import('./newsinformation/FormsInstructions'))}/>
    <Route path={`${match.url}/newsinformation/DefaultPage`} component={asyncComponent(() => import('./newsinformation/DefaultPage'))}/>
    <Route path={`${match.url}/QuickAccess`} component={asyncComponent(() => import('./QuickAccess'))}/>
    
    
  </Switch>
);

export default Portal;
