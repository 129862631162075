import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import getNavLinks from '../NavLinks'

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class HorizontalNav extends Component {

  getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  getSubMenu = (item) => {
    const { pathname, navStyle } = this.props;
    if (item.subMenus != null)
      console.log("item", item);
    var hasPermissionItems = item.subMenus.filter(a => a.permissionKey);// || HasPermission(a.permissionKey));
    console.log("hasPermissionItems", hasPermissionItems);
    if (hasPermissionItems != null && hasPermissionItems.length > 0) {
      return (
        <SubMenu key={item.id} className={this.getNavStyleSubMenuClass(navStyle)}
          title={
            <span>
              {/* <i className={item.icon} /> */}
              <IntlMessages id={item.textKey} />
            </span>
          }>
          {
            hasPermissionItems.map(sub => {
              return (
                <Menu.Item
                  // style={{ width: '100em', whiteSpace: 'normal', height: 'auto ', }}
                  key={sub.id}>
                  <Link to={sub.link}><i className={sub.icon} /><IntlMessages id={sub.textKey} /></Link>
                </Menu.Item>
              )
            })
          }
        </SubMenu>
      )
    } else {
      return (<React.Fragment></React.Fragment>)
    }

  }

  render() {
    const { pathname, navStyle } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    const links = getNavLinks()
    console.log("links", links);
    return (

      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal">

        <Menu.Item key="newsinformation/DefaultPage">
          <Link to="/portal/newsinformation/DefaultPage">
            <IntlMessages id="sidebar.main" />
          </Link>
        </Menu.Item>

        { links.map((item) => this.getSubMenu(item)) }

      </Menu>

    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({ settings }) => {
  const { themeType, navStyle, pathname, locale } = settings;
  return { themeType, navStyle, pathname, locale }
};
export default connect(mapStateToProps)(HorizontalNav);

