const navLinks = [
    {
        id: 10, textKey: 'sidebar.newsinformation', icon: 'icon icon-exchange', subMenus: [
            {
                id: 100,
                textKey: 'sidebar.centralbanknews',
                link: '/portal/newsinformation/centralbanknews',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima'
            }
            ,
            {
                id: 110,
                textKey: 'sidebar.tablo',
                link: '/portal/newsinformation/tablo',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima_Total'
            } ,
            {
                id: 111,
                textKey: 'sidebar.nerkharz',
                link: '/portal/newsinformation/nerkharz',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima_Total'
            } ,
            {
                id: 112,
                textKey: 'sidebar.bongah',
                link: '/portal/newsinformation/bongah',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima_Total'
            } 
        ]
    },
    {
        id: 30, textKey: 'sidebar.FormsInstructions', icon: 'icon icon-exchange', subMenus: [
            {
                id: 300,
                textKey: 'sidebar.FormsInstructions',
                link: '/portal/newsinformation/FormsInstructions',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima'
            }

        ]
    },
    {
        id: 20, textKey: 'sidebar.contactus', icon: 'icon icon-exchange', subMenus: [
            {
                id: 200,
                textKey: 'sidebar.callus',
                link: '/contactus/callus',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima'
            }
            ,
            {
                id: 201,
                textKey: 'sidebar.superuser',
                link: '/contactus/superuser',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima_Total'
            } ,
            {
                id: 202,
                textKey: 'sidebar.TechnicalAssistant',
                link: '/contactus/TechnicalAssistant',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima_Total'
            } ,
            {
                id: 203,
                textKey: 'sidebar.offers',
                link: '/contactus/offers',
                icon: 'icon icon-exchange',
                permissionKey:'Dashboard_Nima_Total'
            } 
        ]
    },
];

const getNavLinks = () => {
    console.log("getNavLinks",navLinks);
    return [...navLinks];
};

export default getNavLinks;
