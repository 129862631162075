import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";


const contactus = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/callus`}/>
    <Route path={`${match.url}/callus`} component={asyncComponent(() => import('./callus'))}/>
    <Route path={`${match.url}/superuser`} component={asyncComponent(() => import('./superuser'))}/>
    <Route path={`${match.url}/TechnicalAssistant`} component={asyncComponent(() => import('./TechnicalAssistant'))}/>
    <Route path={`${match.url}/offers`} component={asyncComponent(() => import('./offers'))}/>
  </Switch>
);

export default contactus;
